import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';

// components
import Header from '../../components/header/Header';
import GridLayout from '../../layouts/gridLayout/GridLayout';
import { team, advisors, supporters } from '../../data/pages/team/teamInfo';
import FormatTeam from '../../components/team/FormatTeam';
import FormatSupporters from '../../components/team/FormatSupporters';

// assets
import './Team.css';
import linkedIn from '../../assets/images/logo/linkedIn.png';
import headerImg from '../../assets/images/headers/railway-elevator.png';

const components = {
  formatTeam: FormatTeam,
  formatSupporters: FormatSupporters
}

const Team = () => {
  const [width, setWidth] = useState(0);
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  const [isEmployee, setIsEmployee] = useState(false);
  const numCols = width >= 700 ? 4 : 2;

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    document.title = "Team — Grain Discovery"

    return function cleanup() {
      window.removeEventListener('resize', updateWindowDimensions);
    }
  });

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  }

  const showModal = (info, type) => {
    setVisible(true);
    setContent(info);
    setIsEmployee(type);
  };

  const handleCancel = () => {
    setVisible(false);
    setContent(null);
  };

  const formatData = (data, component) => {
    const FormatComponent = components[component];
    return (data.map((item, i) => {
      return (
        <FormatComponent item={item} index={i} showModal={showModal} key={i} width={width} />
      );
    }))
  }

  return (
    <div className="Team">
      <Header img={headerImg} alt="A railway and grain elevator" />
      <div className="section-block container">
        <div className="section-title">
          <h1>Meet the Grain Discovery team</h1>
          <p>We're a group of problem-solvers, entrepreneurs, and collaborators who want to change the agricultural industry.</p>

        </div>

        <GridLayout numCols={numCols} data={formatData(team, "formatTeam")} />
      </div>

      <div className="section-block container">
        <div className="section-title">
          {/*<h2>Co-op Students</h2>*/}
        </div>

        {/*<GridLayout numCols={numCols} data={formatData(coops, "formatTeam")} />*/}
        <Modal
          width="800px"
          visible={visible}
          onCancel={handleCancel}
          footer={[]}
          className="team-modal">
          <Row style={{ margin: "3rem" }} gutter={16}>
            <Col span={width >= 650 ? 8 : 24} className={width >= 650 ? 'leftAlign' : 'centered'} >
              <img height="150px" src={content && require('../../assets/images/headshots/' + content.img)} alt={(content || {}).name} style={{ borderRadius: "50%" }}></img>
              <p className="name">{(content || {}).name}</p>
              {isEmployee && <p className="position">{(content || {}).position}</p>}
              {isEmployee && (content || {}).linkedIn && <a href={content.linkedIn} target="_blank" rel="noopener noreferrer"><img src={linkedIn} alt="Linkedin logo" width="25px"></img></a>}
            </Col>
            <Col span={width >= 650 ? 16 : 24} className={width >= 650 ? 'rightAlign' : 'bottomAlign'}>
              <p className="employee-content">{(content || {}).content}</p>
              {isEmployee && <p className="employee-content">{(content || {}).education}</p>}
            </Col>
          </Row>
        </Modal>
      </div>

      {/* <div className="section-block container">
        <div className="section-title">
          <h2>Advisors</h2>
        </div>
        <GridLayout numCols={numCols} data={formatData(advisors, "formatTeam")} />
      </div>

      <div className="section-block container">
        <div className="section-title">
          <h2>Supporters</h2>
          <p>Grain Discovery is truly appreciative of the funding and collaborative assistance we have received from the following organisations that support our vision.</p>
        </div>
        <GridLayout numCols={numCols} data={formatData(supporters, "formatSupporters")} />
      </div> */}

    </div>

  );
}

export default Team;
