import React from 'react';
import Slider from "react-slick";

//assets
import './ClientCarousel.scss'

const ClientCarousel = () => {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 15000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        slidesToShow: 8,
        slidesToScroll: 8,
        pauseonHover: true,
    };

    return (
        <div className="home-section-1">
            <div className="title">
                <h2>Over 15,000 Satisfied Users</h2>
            </div>
            <Slider {...settings}>
                <div>
                    <img
                        src={require('../../../assets/images/clients/ABB-Solutions.png')}
                        alt="ABB Solutions"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Addis.png')}
                        alt="Addis Grain"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Agpro.png')}
                        alt="Agpro"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Arkwood.png')}
                        alt="Arkwood"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Battle-River-Railway.png')}
                        alt="Battle River Railway"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Ceres-Global.png')}
                        alt="Ceres Global"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/clearview.png')}
                        alt="Clearview"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/CourtFarms.png')}
                        alt="Court Farms"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Cox.png')}
                        alt="Cox"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/CrookedCreek.png')}
                        alt="Crooked Creek"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/dark-logo.png')}
                        alt="Npi"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/DGGlobal.png')}
                        alt="DG Global"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Grainshark.png')}
                        alt="Grainshark"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Plover.png')}
                        alt="Grain Millers"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Grant.png')}
                        alt="Grant"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/GreatWestern.png')}
                        alt="Great Western"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Hessels.png')}
                        alt="Hessels"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Hoffsuemmer.png')}
                        alt="Hoffsuemmer"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Huron.png')}
                        alt="Huron"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/lockie.png')}
                        alt="Lockie"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Makers.png')}
                        alt="Makers Malt"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Mapleview.png')}
                        alt="Mapleview"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Monette.png')}
                        alt="Monette"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Normelean.png')}
                        alt="Normelean"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/NorthStar.png')}
                        alt="Northstar"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Ondrejicka.png')}
                        alt="Ondrejicka"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Grain-Millers.png')}
                        alt="Plover Mills"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Primatis.png')}
                        alt="Primatis"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/rkm.png')}
                        alt="RKM"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/ScienceHill.png')}
                        alt="Science Hill"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Sevita.png')}
                        alt="Sevita"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Sharedon.png')}
                        alt="Sharedon"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/StLawrence.png')}
                        alt="St. Lawrence Grain"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Star-Of-The-West.png')}
                        alt="Star Of The West"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Summers.png')}
                        alt="Summers"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/swt.png')}
                        alt="SWT"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/talbot.png')}
                        alt="Talbot"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Twentyview.png')}
                        alt="Twentyview"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/vbd.png')}
                        alt="VDB"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Westlock.png')}
                        alt="Westlock"
                        className='client-image'
                    />
                </div>
                <div>
                    <img
                        src={require('../../../assets/images/clients/Woodrill.png')}
                        alt="Woodrill"
                        className='client-image'
                    />
                </div>
            </Slider>
        </div>
    );
}

export default ClientCarousel;
